.common_icon, .custome_toast .btn-close, .icon_list::before {
    background-color: var(--text-primary-color);
    display: inline-block;
    height: 20px;
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    position: relative;
    width: 20px;
}
.common_icon{background-color: var(--bs-dark);}
.common_icon.white{background-color: var(--bs-body-bg);}
.common_icon.primary, .icon_list.primary::before{background-color: var(--button_color1);}
.common_icon.menu{mask-image: url(./../img/icon/menu.svg);-webkit-mask-image: url(./../img/icon/menu.svg);}
.common_icon.quotes{mask-image: url(./../img/icon/quotes.svg);-webkit-mask-image: url(./../img/icon/quotes.svg);}
.common_icon.CheckCircle{mask-image: url(./../img/icon/CheckCircle.svg);-webkit-mask-image: url(./../img/icon/CheckCircle.svg);}
.common_icon.icon-sm{height: 15px;width: 15px;}
.common_icon.icon-xxl{height: 30px;width: 30px;}
.common_icon.large{height: 60px;width: 60px;}
.icon_list.email::before, .common_icon.email{mask-image: url(./../img/icon/email.svg);-webkit-mask-image: url(./../img/icon/email.svg);}
.icon_list.phone::before, .common_icon.phone{mask-image: url(./../img/icon/phone.svg);-webkit-mask-image: url(./../img/icon/phone.svg);}
.common_icon.CloseButton{mask-image: url(./../img/icon/CloseButton.svg);-webkit-mask-image: url(./../img/icon/CloseButton.svg);}
.icon_list.location::before, .common_icon.location{mask-image: url(./../img/icon/location.svg);-webkit-mask-image: url(./../img/icon/location.svg);}
.common_icon.ArrowLeft,.common_icon.ArrowRight, .common_icon.ArrowTop,.common_icon.ArrowBottom  {mask-image: url(./../img/icon/arrow.svg);-webkit-mask-image: url(./../img/icon/arrow.svg);}
.common_icon.ArrowRight{rotate: 180deg;}
.common_icon.ArrowTop{rotate: 90deg;}
.common_icon.ArrowBottom{rotate: 270deg;}
.icon_list::before{content: "";}
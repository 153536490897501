@media (min-width:500px) {
    body {
        --h1: 2rem;
        --h2: 1.7rem;
        --h3: 1.5rem;
        --h4: 1.3rem;
        --h5: 1.1rem;
        --title-head-2: 55px;
        --title-head-3: 35px;
        --title-head-4: 30px;
        --title-head-5: 25px;
        --title-head-6: 20px;
        --text-header1: 29px;
        --text-header2: 25px;
        --text-header3: 21px;
        --text-header4: 19px;
        --text-header5: 15px;
        --text-header6: 1rem;
    }
}

@media (max-width:1220px) {
    .content_after::after {
        font-size: 1.4rem;
    }

    .arrow_pointing .button_section img.animated {
        max-width: 35%;
        top: -16px;
    }
}

@media (max-width:1024px) {
    .desk_menu {
        display: none !important;
    }

    .mobile_menu {
        display: block;
    }

    .header_top {
        padding: 0.5rem 10px !important;
    }

    .header-section {
        padding: 30px 10px;
    }

    .content_after::after {
        padding-right: 0px;
        max-width: 160px;
    }

    .home_carousel .carousel.slide .carousel-item .container {
        width: 100%;
    }

    .home_content_wrapper .slider_row_carousel {
        width: 100%;
    }

    .home_content_wrapper .slider_row_carousel:first-child {
        padding-top: 1.5rem;
    }

    .home_carousel .slider_arrow_wrapper img {
        width: 30%;
    }

    .arrow_pointing .button_section {
        position: relative;
        padding-top: 3rem !important;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }

    .arrow_pointing .button_section .button {
        position: absolute
    }

    .home .home_carousel .home_content {
        padding-bottom: 0rem;
    }

    .arrow_pointing .button_section img.animated {
        max-width: 30%;
        margin-left: auto;
        margin-right: 5%;
        position: relative;
        top: -2rem;
    }

    .home .home_carousel .home_content .content_wrapper {
        text-align: center;
    }

    .banner-section {
        padding-bottom: 3rem;
    }
}

@media (max-width:998px) {
    .home .home_wrapper .home_carousel .home_content {
        padding-left: 12px;
    }

    .home_page_carousel {
        background-position: 85%;
    }

    .home_carousel .carousel-control-next {
        right: 40% !important;
    }

    .home_carousel .carousel-control-prev {
        left: 40% !important;
    }

    .slider_row_carousel .content_image .image_static {
        right: -30px
    }

    .slider_row_carousel .content_image img:last-child {
        left: -10px;
    }

    .section_product .story_content .col-6 {
        width: 100%;
        text-align: center;
    }

    .section_product .story_content .col-6.right {
        justify-content: center;
    }

    .section_product .story_content .right .btn {
        margin-right: 0px;
    }

    .pal_rich_header {
        width: 100% !important;
        height: auto !important;
    }
    .tab_flex_row{
        justify-content: center;
      }
}

@media (max-width:900px) {
    .product_item .product_container .product_wrapper .product_content {
        --title-head-5: 23px;
    }

    .product_item .product_container .product_wrapper .product_content .product_info ul li {
        font-size: 16px;
    }

    .image-grid .image-row {
        column-count: 2;
        --webkit-column-count: 2;
        --moz-column-count: 2;
        gap: 0.4em;
    }

    .image-grid .image-row .image {
        padding-bottom: 0.4em;
    }

    .content_wrapper {
        float: left;
        width: 100%;
        position: absolute;
        height: 100%;
        top: 35%;
    }

    .header_side_content {
        font-size: 15px !important;
        line-height: 30px;
    }

    .header_side_main_content {
        font-size: 24px !important;
        /* line-height: 40px !important; */
    }


    .scroll_img {
        height: auto;
        padding: 0;
    }

    .pal_rich_header {
        width: 100% !important;
        height: auto !important;
    }

    .product_palmur_richson {
        font-size: 20px;
    }

    .sub_product_text {
        font-size: 20px;
    }
    .tab_flex_row{
        justify-content: center;
      }
}

@media (max-width:780px) {

    .product_item .product_container .product_wrapper .product_content,
    .product_item .product_container .product_wrapper .product_image_section {
        width: 100%;
        max-width: 100%;
    }

    .product_item .product_container .product_wrapper .product_image_section img {
        max-width: 68%;
        margin: 0 auto;
    }

    .product_item .product_container .product_wrapper .product_image_section {
        padding-bottom: 1.2rem;
    }

    .contact_us .form_section form {
        max-width: 100%;
    }

    .slider_row_carousel {
        height: 100%;
    }

    .product_palmur_richson {
        font-size: 20px;
    }

    .sub_product_text {
        font-size: 20px;
    }
    
    .header_side_main_content {
        font-size: 20px !important;
        /* line-height: 40px !important; */
    }
}

@media(max-width:800px) {
    .slider_row_carousel {
        height: 100%;
    }

    .header_side_content {
        font-size: 15px !important;
        line-height: 30px;
    }


    .scroll_img {
        height: auto;
        padding: 0 !important;
    }

    .pal_rich_header {
        width: 100% !important;
        height: auto !important;
    }

    .product_palmur_richson {
        font-size: 20px;
    }

    .sub_product_text {
        font-size: 20px;
    }
    .brand_header{
        font-size: 25px !important;
      }
      .tab_flex_row{
        justify-content: center;
      }
      
    .header_side_main_content {
        font-size: 24px !important;
        /* line-height: 40px !important; */
    }
    .product_quality {
        font-size: 1rem;
      }
}

@media (max-width:580px) {
    .home_carousel .carousel-control-next {
        right: 30% !important;
    }

    .home_carousel .carousel-control-prev {
        left: 30% !important;
    }

    .home_carousel .slider_btn.icon_button i {
        height: 15px;
        width: 15px;
    }

    .arrow_pointing .button_section .button {
        font-size: 14px;
        min-width: 120px;
        --bs-btn-padding-x: 0.5rem;
        --bs-btn-padding-y: 0.7rem;
    }

    .arrow_pointing .button_section img.animated {
        max-width: 146px;
        top: -1rem;
    }

    .box-card.card.brand_card img {
        max-height: 60px;
    }

    .theme_image_section .theme_image {
        height: 25px;
    }

    .box-card.card.brand_card {
        min-height: 160px;
        --title-head-4: 20px
    }

    .video_content .video_wrapper .hover_content p {
        display: none;
    }

    .video_content .video_wrapper .hover_content .video_player {
        margin-bottom: 0px;
    }

    .product_palmur_richson {
        font-size: 20px;
    }

    .sub_product_text {
        font-size: 20px;
    }

    
    .header_side_main_content {
        font-size: 24px !important;
        /* line-height: 40px !important; */
    }
    .product_quality {
        font-size: 1rem;
      }
}

@media (max-width:480px) {
    .content_after::after {
        font-size: 14px;
        max-width: 25%;
    }

    body .button.btn {
        font-size: 12px;
        min-width: 120px;
        --bs-btn-padding-x: 1rem;
        --bs-btn-padding-y: 0.55rem;
    }

    .arrow_pointing .button_section img.animated {
        padding-left: 0px;
        max-width: 92px;
    }

    .home_content_wrapper .slider_row_carousel:last-child {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .slider_row_carousel .content_image .image_static {
        right: -10px;
    }

    .body {
        --h1: 1.8rem;
        --h2: 1.5rem;
        --h3: 1.3rem;
        --h4: 1.2rem;
        --h5: 1rem;
        --title-head-1: 35px;
        --title-head-2: 33px;
        --title-head-3: 29px;
        --title-head-4: 24px;
        --title-head-5: 20px;
        --title-head-6: 15px;
        --title-head-7: 18px;
        --title-head-8: 16px;
        --text-header1: 29px;
        --text-header2: 25px;
        --text-header3: 21px;
        --text-header4: 19px;
        --text-header5: 15px;
        --text-header6: 1rem;
        --title-primary: 20px;
    }

    .home .home_carousel .home_content {
        padding-top: 0rem;
    }

    .arrow_pointing .button_section {
        padding-top: 1rem !important;
    }

    .section_product .story_content {
        --title-head-4: 20px;
        --title-head-5: 14px;
    }

    .product_item .product_container .product_wrapper .product_image_section,
    .product_item .product_container .product_wrapper .product_content {
        padding-left: 0px;
        padding-right: 0px;
    }

    .product_item .product_container .product_wrapper .product_content {
        --title-head-5: 20px;
    }

    .product_item .product_container .product_wrapper .product_content .product_info ul li {
        font-size: 14px;
    }

    .product_item .product_container .product_wrapper .product_content .product_heading .product_title::after {
        width: 80px;
    }

    .theme_image_section .theme_image {
        height: 22px;
    }

    .home .container-lg {
        padding-left: 15px;
        padding-right: 15px;
    }

    .home .container-lg .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    /* .banner_background{background-attachment: scroll;} */
    .theme_image_section .box-card.card {
        min-height: 130px;
        --title-head-4: 16px;
        --title-head-6: 14px
    }

    .box-card.card.brand_card img {
        max-height: 40px;
    }

    .header-section {
        padding: 15px 10px;
    }

    .body_layout {
        /* padding-top: 4rem; */
        width: 100%;
    }

    .logo {
        max-width: 40px;
    }

    .video_content .video_wrapper .hover_content .video_player {
        height: 40px;
        width: 40px;
    }

    .section_product .story_content {
        padding-bottom: 3rem;
    }

    .preview_carousel .carousel-control-next,
    .preview_carousel .carousel-control-prev {
        max-width: 20px !important;
        min-width: 20px !important;
    }

    .modal {
        --title-head-3: 25px;
        --title-head-4: 20px;
    }

    .modal.modal_theme .modal_logo img.lg {
        width: 100%;
    }

    .home .home_wrapper .home_carousel .home_content {
        padding-top: 0rem;
    }

    .product_palmur_richson {
        font-size: 20px;
    }

    .sub_product_text {
        font-size: 20px;
    }

    .product_text {
        font-size: 45px;
    }

    .brand_header {
        font-size: 25px !important;
    }

    
    .header_side_main_content {
        font-size: 24px !important;
        /* line-height: 40px !important; */
    }
    .product_quality {
        font-size: 1rem;
      }
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/pt_font400.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/pt_font4001.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/pt_font4002.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/pt_font4003.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/pt_font700.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/pt_font7001.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/pt_font7002.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/pt_font7003.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* vietnamese */
@font-face {
  font-family: 'Allura';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./../fonts/Allura_font_400_normal.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Allura';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./../fonts/Allura_font_400_normal1.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Allura';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./../fonts/Allura_font_400_normal2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Orbitron';
  font-style: 60;
  font-weight: 900;
  font-display: swap;
  src: url(./../fonts/Orbitron-Black.ttf) format('ttf');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(./../fonts/yMJRMIlzdpvBhQQL_Qq7dy0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* manrope-regular - latin */
/* @font-face {
  font-display: swap; 
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url(./../fonts/manrope-v15-latin-regular.woff2) format('woff2');
} */

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(./../fonts/xn7gYHE41ni1AdIRggqxSuXd.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(./../fonts/xn7gYHE41ni1AdIRggOxSuXd.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(./../fonts/xn7gYHE41ni1AdIRggSxSuXd.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(./../fonts/xn7gYHE41ni1AdIRggixSuXd.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(./../fonts/xn7gYHE41ni1AdIRggmxSuXd.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(./../fonts/xn7gYHE41ni1AdIRggexSg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  --font-family: 'PT Serif', sans-serif, serif;
  --font-family_title: 'Allura', cursive;
  --font-size: 0.9rem;
  --orange-1: 188, 153, 89;
  --orange: rgb(188, 153, 89);
  --primary-secondary: #AA9E89;
  --button_color1: #BC9959;
  --button_color2: #8B6D35;
  --btn-primary: transparent linear-gradient(253deg, var(--button_color1) 0%, var(--button_color2) 100%);
  --text-primary: rgb(var(--orange-1));
  --text-primary-rgb: var(--orange-1);
  --text-primary-trans: rgb(var(--orange-1), 0.3);
  --h1: 2rem;
  --h2: 1.7rem;
  --h3: 1.5rem;
  --h4: 1.3rem;
  --h5: 1.1rem;
  --title-head-1: 45px;
  --title-head-2: 40px;
  --title-head-3: 35px;
  --title-head-4: 30px;
  --title-head-5: 25px;
  --title-head-6: 20px;
  --title-head-7: 18px;
  --title-head-8: 16px;
  --text-header1: 29px;
  --text-header2: 25px;
  --text-header3: 21px;
  --text-header4: 19px;
  --text-header5: 15px;
  --text-header6: 1rem;
  --title-primary: 20px;
  --title-secondary: 16px;
  --h6: var(--font-size);
  --text-secondary: #6c757d;
  --text-primary-1: #A08D79;
  --text-primary-2: #AA9E89;
  --text-primary-3: #9B856E;
  --text-primary-4: #D8B662;
  --text-primary-5: #AA9E8A;
  --text-secondary1: #8E8E8E;
  --text-secondary2: #7B6D61;
  --color-white: #fff;
  --white-text: #fff;
  --white-bg: #fff;
  --bg-default-layout: #1F1B17;
  --color-black: #000;
  --color-black-rgb: rgb(0, 0, 0);
  --text-color: rgb(0, 0, 0);
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: var(--orange);
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: hwb(0 0% 100%);
  --bs-primary-rgb: var(--orange-1);
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: var(--font-size);
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: var(--text-color);
  --bs-body-bg: var(--white-bg);
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.transition {
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.section {
  position: relative;
  width: 100%;
}

.overlay {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.overlay::before {
  background-color: var(--color-black);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.overlay.overlay-trans::before {
  background-color: #00000000;
}

.banner_background {
  /* background-image: url(./../img/home/bg.webp); */
  height: 100%;
  min-height: 100vh;
  position: relative;
  color: var(--color-white);
  background-attachment: fixed;
}

/* .body_layout {
  padding-top: 8rem;
} */

.body_layout.theme-default {
  background-color: var(--bg-default-layout) !important;
}

body {
  scrollbar-color: #404040b3 transparent;
}

body::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

body::-webkit-scrollbar-track {
  display: none !important;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 0px solid transparent;
  background-color: var(--text-primary);
  background-clip: content-box;
}

.header-section {
  background-color: transparent;
  padding: 30px 0;
  z-index: 99999;
  position: fixed;
  top: 0px;
}

.header_top {
  background-color: #31312fde;
  padding: 0.5rem 2rem !important;
  backdrop-filter: blur(4px);
}

/* .header_top .container-xl{max-width: 100%;} */
.header-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo {
  max-width: 50px;
  padding: 2px 0;
}

.logo img {
  width: 100%;
  height: auto;
}

.header-center.text-right ul {
  text-align: right;
}

.header-top .desk_menu {
  display: flex;
  align-items: center;
}

.desk_menu .header-right {
  margin-left: 50px;
}

.mobile_menu {
  display: none;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
}

.nav-menus>li {
  margin-right: 5px;
  display: inline-flex;
  position: relative;
}

.nav-menus>li a {
  /* color: var(--primary-secondary); */
  padding: 10px 20px;
  position: relative;
  display: block;
}

.nav-menus>li a span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menus>li a span::after {
  content: "";
  height: auto;
  background-color: var(--primary-secondary);
  position: absolute;
  bottom: -4px;
  display: block;
  width: 0%;
  transition: width .2s;
}

.nav-menus>li a:hover span::after,
.nav-menus>li a.active span::after {
  width: 100%;
  border: 1px solid transparent;
}

body a {
  text-decoration: none;
  color: var(--bs-body-color);
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body p {
  margin: 0px;
}

body h5 {
  font-size: 1.20rem;
}

.text-style {
  --bs-body-font-family: var(--font-family_title);
  font-family: var(--bs-body-font-family);
}

.body img {
  -webkit-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  transition: all .7s ease;
}

.body .text-primary,
.text.primary {
  color: var(--text-primary);
}

.body .text.primary2 {
  color: var(--text-primary-2);
}

.body .text.primary3 {
  color: var(--text-primary-3);
}

.body .text.primary4 {
  color: var(--text-primary-4);
}

.body .text.secondary {
  color: var(--text-secondary);
}

.body .text.secondary1 {
  color: var(--text-secondary1);
}

.body .text.secondary2 {
  color: var(--text-secondary2);
}

.body .text.normal {
  color: var(--text-color);
}

.body .text-capitalize {
  /* text-transform: capitalize; */
}

.body .text.white {
  color: var(--white-text);
}

.text.grey {
  color: var(--txt-grey);
}

.body .text-header1 {
  font-weight: 700;
  font-size: var(--text-header1);
  margin-bottom: 10px;
}

.body .text-header2 {
  font-weight: 700;
  font-size: var(--text-header2);
  margin-bottom: 10px;
}

.body .text-header3 {
  font-weight: 700;
  font-size: var(--text-header3);
  margin-bottom: 10px;
}

.body .text-header4 {
  font-weight: 700;
  font-size: var(--text-header4);
  margin-bottom: 10px;
}

.body .text-header5 {
  font-weight: 700;
  font-size: var(--text-header5);
  margin-bottom: 10px;
}

.body .text-header6 {
  font-weight: 700;
  font-size: var(--text-header6);
  margin-bottom: 10px;
}

.body .title-head-1 {
  font-size: var(--title-head-1);
}

.body .title-head-2 {
  font-size: var(--title-head-2);
}

.body .title-head-3 {
  font-size: var(--title-head-3);
}

.body .title-head-4 {
  font-size: var(--title-head-4);
}

.body .title-head-5 {
  font-size: var(--title-head-5);
}

.body .title-head-6 {
  font-size: var(--title-head-6);
}

.body .title-head {
  font-weight: 700;
  /* line-height: 1.3; */
}

.body .font-1 {
  font-size: var(--title-head-1);
}

.body .font-2 {
  font-size: var(--title-head-2);
}

.body .font-3 {
  font-size: var(--title-head-3);
}

.body .font-4 {
  font-size: var(--title-head-4);
}

.body .font-5 {
  /* font-size: var(--title-head-5); */
}

.body .font-6 {
  font-size: var(--title-head-6);
}

.body .font-7 {
  font-size: var(--title-head-7);
}

.body .font-8 {
  font-size: var(--title-head-8);
}

.body .title-secondary {
  font-size: var(--title-secondary);
  margin-bottom: 15px;
}

.body .title-primary {
  font-size: var(--title-primary);
  margin-bottom: 15px;
}

/* Button */
body .btn.icon_button {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

body .btn.icon_button.btn-sm {
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-padding-y: 0.5rem;
}

body .btn.icon_button.btn-sm .common_icon {
  height: 15px;
  width: 15px;
}

.btn.icon_button.rounded {
  --bs-btn-border-radius: 60px;
  --bs-border-radius: var(--bs-btn-border-radius)
}

.button.btn {
  --bs-btn-padding-x: 1.2rem;
  --bs-btn-padding-y: 0.75rem;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  display: inline-flex;
}

body .btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

body .btn.button.btn-sm {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.6rem;
  --bs-btn-font-size: 0.8rem;
  min-width: 90px;
}

body .btn.rounded-0,
.box-card.border-radius-0,
.form_group.radius-0 {
  --bs-border-radius: 0px;
  --bs-btn-border-radius: 0px
}

body .btn.rounded-1,
.box-card.border-radius-1,
.form_group.radius-1 {
  --bs-border-radius: 0.375rem;
  --bs-btn-border-radius: 0.375rem
}

body .btn.rounded-2,
.box-card.border-radius-2,
.form_group.radius-2 {
  --bs-border-radius: 5px;
  --bs-btn-border-radius: 5px
}

body .btn.rounded-3,
.box-card.border-radius-3,
.form_group.radius-3 {
  --bs-border-radius: 7px;
  --bs-btn-border-radius: 7px
}

body .btn.rounded-4,
.box-card.border-radius-4,
.form_group.radius-4 {
  --bs-border-radius: 10px;
  --bs-btn-border-radius: 10px
}

body .btn.rounded-5,
.box-card.border-radius-5,
.form_group.radius-5 {
  --bs-border-radius: 100px;
  --bs-btn-border-radius: 100px
}

body .btn.rounded-6,
.box-card.border-radius-6,
.form_group.radius-6 {
  --bs-border-radius: 0px;
  --bs-btn-border-radius: 0px
}

body .btn:hover {
  color: var(--bs-btn-hover-color);
  background: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

@media (prefers-reduced-motion:reduce) {
  body .btn {
    transition: none
  }
}

body .btn-check+.btn:hover {
  background: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
  color: var(--bs-btn-color)
}

body .btn:focus-visible {
  background: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  color: var(--bs-btn-hover-color);
  outline: 0
}

body .btn-primary {
  --button-color: var(--text-color);
  --bs-primary: var(--bs-btn-bg);
  --bs-btn-color: var(--button-color);
  --bs-btn-bg: var(--btn-primary);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--button-color);
  --bs-btn-hover-bg: var(--btn-primary);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--button-color);
  --bs-btn-active-bg: var(--btn-primary);
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--button-color);
  --bs-btn-disabled-bg: var(--btn-primary);
  --bs-btn-disabled-border-color: transparent;
  background: var(--bs-btn-bg);
}

body .btn-primary-outline {
  --button-color: var(--white-text);
  --bs-btn-color: var(--button-color);
  --bs-btn-bg: transparent;
  --bs-primary: transparent;
  --bs-btn-border-color: var(--text-primary);
  --bs-btn-hover-color: var(--text-color);
  --bs-btn-hover-bg: var(--btn-primary);
  --bs-btn-hover-border-color: var(--text-primary);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--text-color);
  --bs-btn-active-bg: var(--bs-btn-bg);
  --bs-btn-active-border-color: var(--text-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--text-color);
  --bs-btn-disabled-bg: var(--bs-btn-bg);
  --bs-btn-disabled-border-color: var(--text-primary);
  background: var(--bs-btn-bg);
}

body .btn-normal {
  --bs-btn-color: #fff;
  --bs-btn-bg: transparent;
  --bs-primary: var(--bs-btn-bg);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
  background: var(--bs-btn-bg);
}

body .btn-white {
  --bs-btn-color: var(--text-color);
  --bs-btn-bg: var(--white-bg);
  --bs-primary: var(--bs-btn-bg);
  --bs-btn-border-color: var(--white-bg);
  --bs-btn-hover-color: var(--text-color);
  --bs-btn-hover-bg: var(--white-bg);
  --bs-btn-hover-border-color: var(--white-bg);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--text-color);
  --bs-btn-active-bg: var(--white-bg);
  --bs-btn-active-border-color: var(--white-bg);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--text-color);
  --bs-btn-disabled-bg: var(--white-bg);
  --bs-btn-disabled-border-color: var(--white-bg);
  background: var(--bs-btn-bg);
}

.ripple-button {
  position: relative;
  overflow: hidden;
}

.ripple-button>.ripple {
  width: 40px;
  height: 40px;
  position: absolute;
  background: var(--bs-primary);
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 0;
  animation: 1s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(10);
    opacity: 0.5;
  }

  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.ripple-button>.content {
  position: relative;
  z-index: 2;
}

/* boxCard */
/* .card-variant-normal{--card-bg:var(--white-bg)} */
.card-variant-primary3 {
  --card-bg: #47423e7d
}

.box-card.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: 100%;
  --bs-card-color: var(--text-color);
  --bs-card-bg: var(--card-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.box-card.card.brand_card {
  text-align: center;
  --bs-card-color: var(--white-text);
  min-height: 250px;
}

.box-card.card.brand_card {
  background-image: url(./../img/home/box_card_theme.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  --bs-card-bg: transparent
}

.box-card.card.brand_card .card-body {
  padding: 1.5rem 1.5rem 2rem;
}

.box-card.card.brand_card img {
  max-height: 95px;
  width: 100%;
  object-fit: contain;
  height: 100%;
  max-width: 100%;
}

.box-card.card.brand_card .card-body {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Input Form */
.form-size-1 {
  --fr-spacer-y: 0.45rem;
  --fr-spacer-x: 0.75rem;
}

.form-size-2 {
  --fr-spacer-y: 0.75rem;
  --fr-spacer-x: 1rem;
}

.form-size-3 {
  --fr-spacer-y: 1rem;
  --fr-spacer-x: 1rem;
}

.form-size-4 {
  --fr-spacer-y: 0.75rem;
  --fr-spacer-x: 1rem;
}

.form-size-5 {
  --fr-spacer-y: 0.75rem;
  --fr-spacer-x: 1rem;
}

.form-size-6 {
  --fr-spacer-y: 0.75rem;
  --fr-spacer-x: 1rem;
}

.body .form_group {
  --fr-transition: all .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  --fr-border-radius: var(--bs-border-radius);
  --fr-border-color: #ced4da;
  --fr-border-focus-color: #ced4da;
  --fr-danger-border-color: #dc3545;
  --fr-border-width: 1px;
  --fr-border-style: solid;
  --fr-color: var(--text-color);
  --fr-line-height: var(--bs-body-line-height);
  --fr-font-size: var(--bs-body-font-size);
}

.form_group.form_style_underline .form-control {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}

.form_group .form-control.is-invalid:focus,
.form_group .was-validated .form-control:invalid:focus {
  border-color: var(--fr-danger-border-color);
  box-shadow: none;
}

.form_group .form-control.is-invalid,
.form_group .was-validated .form-control:invalid {
  border-color: var(--fr-danger-border-color);
  padding-right: calc(1.5em + var(--fr-spacer-x));
  background-image: url(./../img/icon/warning.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.body .form-control {
  display: block;
  width: 100%;
  /* padding: var(--fr-spacer-y) var(--fr-spacer-x); */
  font-size: var(--fr-font-size);
  font-weight: 400;
  line-height: var(--fr-line-height);
  color: var(--fr-color);
  background-color: rgb(233 233 233 / 60%);
  background-clip: padding-box;
  border: var(--fr-border-width) var(--fr-border-style) var(--fr-border-color);
  appearance: none;
  border-radius: var(--fr-border-radius);
  transition: var(--fr-transition);
}

.body .form-control:focus {
  color: var(--fr-color);
  background-color: rgb(233 233 233 / 60%);
  border-color: var(--fr-border-focus-color);
  outline: 0;
  box-shadow: none;
}

/* Home Page */
.banner-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  position: relative;
  width: 100%;
}

.banner-section .pt-2.m-0.row {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}

.home .home_wrapper .home_carousel .home_content {
  padding: 5rem 0rem 0rem 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.home .home_wrapper .home_carousel .home_content .title-head {
  /* margin-bottom: 15px; */
}

.home .home_wrapper .home_carousel .home_content p {
  font-size: var(--h4);
  margin-bottom: 20px;
}

.home_carousel .carousel-control-next button:hover i,
.home_carousel .carousel-control-prev button:hover i {
  background-color: var(--text-primary);
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.home_carousel .slider_btn.icon_button i {
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.home_carousel .slider_btn.icon_button {
  --bs-btn-border-color: var(--text-primary-2);
  --bs-btn-hover-border-color: var(--text-primary-2);
  --bs-btn-active-border-color: var(--text-primary-2);
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.home_carousel .slider_btn.icon_button.left {
  border-style: solid solid solid dashed;
}

.home_carousel .slider_btn.icon_button.right {
  border-style: solid dashed solid solid;
}

.content_after::after {
  display: block;
  content: attr(data-content);
  color: var(--bs-primary);
  bottom: -45px;
  margin-left: auto;
  position: relative;
  text-align: right;
  padding-right: 90px;
  font-family: var(--font-family_title);
  font-size: 1.6rem;
  width: 260px;
  position: absolute;
  right: 20px;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.01s ease-out;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

body.loaded .has-animation.active-animation .content_after::after {

  -webkit-transform: translateY(0) translateX(0);
  -ms-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
  opacity: 1 !important;
  visibility: visible !important;
}

.home_carousel .carousel-item {
  /* padding-bottom: 45px; */
}

.home_carousel .carousel-control-next,
.home_carousel .carousel-control-prev {
  width: auto;
  top: auto;
  opacity: 1;
}

.home_carousel .carousel-control-next {
  right: 45% !important;
}

.home_carousel .carousel-control-prev {
  left: 45% !important;
}

.slider_row_carousel {
  position: relative;
}

.slider_row_carousel .content_image {
  text-align: right;
  max-width: 100%;
}

.slider_row_carousel .content_image img {
  position: relative;
  display: inline;
  max-width: 50%;
  height: auto;
  object-fit: cover;
  object-position: top;
  margin-left: auto;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-window-dragging: no-drag;
  transition: opacity 1.5s;
  width: 100%;
}

.slider_row_carousel .content_image .image_static {
  right: -60px;
}

.theme_image_section .theme_image {
  position: relative;
  margin: 0 auto;
  width: auto;
  display: block;
  height: 30px;
}

.theme_image_section.type-card .theme_image {
  bottom: -5px;
  z-index: 1;
}

.arrow_pointing .button_section {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.arrow_pointing .button_section img.animated {
  position: relative;
  top: -45px;
  max-width: 40%;
  padding-left: 20px;
}

.theme_white {
  color: var(--color-white);
}

.theme_dark {
  color: var(--text-color);
}

.bg_dark {
  background-color: #1E1A16;
}

.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section_product.bg_image_background {
  background-image: url(./../img/home/bg_image.webp);
}

.bg_map_background {
  background-image: url(./../img/home/map.webp);
}

.section_product .story_content {
  padding: 2rem 0px;
}

.section_product .story_content .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.section_product .story_content .right .btn {
  margin-right: 20px;
}

/* Product Item */
.product_item .product_container {
  display: block;
  position: relative;
  padding-top: 40px;
}

.product_item .product_container .product_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product_item.product_flip .product_container .product_wrapper {
  flex-direction: row-reverse;
}

.product_item .product_container .product_wrapper>* {
  padding: 0px 1.2rem;
}

.product_item .product_container .product_wrapper .product_image_section {
  width: 50%;
  max-width: 50%;
}

/* .product_item.product_flip .product_container .product_wrapper .product_image_section{padding-left: 10%;} */
.product_item .product_container .product_wrapper .product_image_section img {
  max-width: 90%;
  display: block;
  width: 100%;
  height: auto;
}

.product_item .product_container .product_wrapper .product_content {
  width: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.product_item .product_container .product_wrapper .product_content .product_heading .product_title::after {
  content: "";
  background-image: url(./../img/product/content_image.webp);
  display: block;
  background-repeat: no-repeat;
  padding-top: 8%;
  background-size: contain;
  width: 120px;
  background-position: center;
}

.product_item .product_container .product_wrapper .product_content .product_info ul {
  padding: 0px;
}

.product_item .product_container .product_wrapper .product_content .product_info ul li {
  color: var(--text-primary-1);
  font-size: 20px;
  padding: 0.4rem 0.5rem;
  font-weight: 400;
}

.product_item .product_container .product_wrapper .product_content .product_info ul li::before {
  display: inline-block;
  content: attr(data-content)':';
  color: var(--white-text);
  min-width: 110px;
}

.experience_content .card .card-body h5 {
  min-height: 50px;
}

.experience_content .card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.experience_content .card .card-body .learn_more {
  position: relative;
  display: inline-block;
  line-height: 2;
}

.experience_content .card .card-body .learn_more:hover {
  color: var(--text-primary);
}

.experience_content .card .card-body .learn_more::after {
  height: auto;
  width: 100%;
  content: "";
  display: block;
  border: 1px solid var(--button_color1);
}

.experience_content .card.box-card {
  max-width: 420px;
  margin: 0 auto !important;
}

.video_content .video_wrapper {
  position: relative;
}

.video_content .video_wrapper img {
  width: 100%;
}

.video_content .video_wrapper .hover_content {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0px;
  background-color: #0000008c;
}

.video_content .video_wrapper .hover_content .video_player {
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
  background-image: url(./../img/home/video_player.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.video_content .video_wrapper .hover_content p {
  max-width: 50%;
  text-align: center;
}

/* .theme_image_section */
.theme_image_section.type-card .theme_image {
  margin-bottom: -35px;
  bottom: 30px;
  z-index: 2;
}

.contact_us .form_section form {
  max-width: 80%;
  font-size: var(--title-head-8);
}

.contact_us .details_section .details_contact_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact_us .details_section .details_contact_wrapper .contact_details_list {
  padding: 0px;
}

.contact_us .details_section .details_contact_wrapper .icon_list {
  color: var(--text-secondary2);
  padding: 0.4rem 0.4rem;
  display: flex;
  align-items: center;
  font-size: var(--title-head-6);
}

.contact_us .details_section .details_contact_wrapper .icon_list::before {
  margin-right: 10px;
  height: var(--title-head-7);
  width: var(--title-head-7);
}

/* footer */
.footer .col_logo .logo_content img {
  max-width: 70px;
  height: 100%;
  object-fit: contain;
}

.footer .col_logo p {
  max-width: 350px;
}

footer.footer,
footer.footer a {
  --color-white: var(--text-primary-5);
  --bs-body-color: var(--text-primary-5);
  --bs-link-hover-color: var(--bs-primary)
}

.footer-of-page .footer-content {
  margin: 0 auto;
}

.footer-of-page .footer-content .footer-item {
  padding-bottom: 30px;
}

.footer-of-page .footer-content .footer-item .sub-foot a {
  display: block;
  line-height: 24px;
  margin-bottom: 16px;
  transition: 0.3s;
}

.footer-of-page .footer-content .footer-item .sub-foot a .span_content {
  position: relative;
  border: 1px solid transparent;
}

.footer-of-page .footer-content .footer-item .sub-foot a .span_content::after {
  content: "";
  height: auto;
  position: absolute;
  bottom: -4px;
  display: block;
  width: 0%;
  border: 1px solid transparent;
  transition: width .2s;
  overflow: hidden;
}

.footer-of-page .footer-content .footer-item .sub-foot a:hover {
  scale: 1.02;
}

.footer-of-page .footer-content .footer-item .sub-foot a:hover .span_content::after {
  width: 100%;
  background-color: var(--primary-secondary);
}

.footer-of-page .footer-content .footer-item .titles {
  display: block;
  line-height: 24px;
  margin-bottom: 16px;
  transition: 0.3s;
  color: var(--white-text);
}

.footer-of-page .footer_bottom {
  padding: 2rem 1rem;
  text-align: center;
  position: relative;
}

.footer-of-page .footer_bottom::before {
  content: "";
  height: 1px;
  display: block;
  background: transparent linear-gradient(270deg, #1E1A16 0%, #3C352D 18%, #3C352D 74%, #1E1A16 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
}

.footer-of-page .section {
  padding-bottom: 0pc;
}

/* Loader */
.suspense_loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 199;
  flex-direction: column;
}

.suspense_loader .loader-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.suspense_loader .loader-body h4 {
  font-size: 12px;
  padding-top: 10px;
  margin-bottom: 0px;
}

.suspense_loader .loader-body img {
  height: 50px;
}

.image-grid {
  width: 100%;
}

.image-grid .image-row {
  column-count: 3;
  --webkit-column-count: 3;
  --moz-column-count: 3;
  column-fill: balance;
  gap: 1em;
}

.image-grid .image-row .image {
  padding-bottom: 1em;
  position: relative
}

.image-grid .image-row .image .gallery__thumb {
  position: relative;
  overflow: hidden;
}

.image-grid .image-row .image .gallery__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid figure {
  margin: 0 0 0rem !important;
  background-color: #000;
}

.image-grid .image-row .image:hover .gallery__image {
  scale: 1.2;
  cursor: pointer;
}

.body .modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: var(--text-color);
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  line-height: var(--bs-body-line-height);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding: 0px !important;
}

.body .modal.modal_theme {
  --bs-modal-border-color: transparent;
  --bs-modal-bg: #43413f;
  --bs-modal-color: white;
  --bs-body-line-height: 1.5;
}

.body .modal.modal_theme .modal-footer {
  --bs-modal-footer-border-color: transparent;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.body .modal.modal_theme .modal-footer .button {
  margin: 0px !important;
  min-width: 120px;
}

.modal.modal_theme .modal_logo img.sm {
  width: 70px;
  height: auto;
  max-width: 70px;
  max-height: 100%;
  object-fit: contain;
  -o-object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.modal.modal_theme .modal_logo img.lg {
  height: auto;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

/* .body .modal.modal_theme */
.modal_preview .modal_preview_dialog {
  height: 100% !important;
  margin: 0px !important;
  max-width: 100% !important;
  padding: 1rem 1rem;
  --bs-modal-bg: #5e62635e;
  --bs-modal-color: var(--white-text);
  backdrop-filter: blur(2px);
}

.modal_preview .modal_preview_dialog .modal-content {
  height: 100%;
  overflow: hidden;
}

.modal_preview .close_button {
  right: 0px;
  top: 0px;
  position: absolute;
  z-index: 99;
}

.modal_preview .modal-body,
.modal_preview .modal-body .carousel,
.modal_preview .modal-body .carousel .carousel-inner,
.modal_preview .modal-body .carousel .carousel-inner>* {
  height: 100%;
  width: 100%;
}

.preview_content img,
.preview_content video {
  height: 100%;
  margin: 0 auto;
  display: block;
  object-fit: contain;
  width: 100%;
}

.preview_content video {
  position: relative;
  z-index: 2;
}

.preview_content {
  position: relative;
}

.preview_carousel .carousel-control-next,
.preview_carousel .carousel-control-prev {
  min-width: 90px;
  z-index: auto !important;
}

.modal_preview.video .carousel-control-next,
.modal_preview.video .carousel-control-prev,
.modal_preview.video .slider_counter {
  display: none;
}

.preview_carousel .carousel-control-next-icon,
.preview_carousel .carousel-control-prev-icon {
  height: 1.5rem;
  width: 1.5rem;
  z-index: 3;
  position: relative;
}

.modal_preview .slider_counter {
  position: absolute;
  z-index: 1;
}

.body .offcanvas {
  --bs-offcanvas-bg: var(--bs-body-bg);
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out;
}

.body .offcanvas.theme_dark {
  --bs-offcanvas-bg: var(--bs-dark);
  --bs-offcanvas-color: var(--white-text);
  --bs-body-color: var(--white-text);
  font-size: var(--bs-body-font-size);
}

.offcanvas.side_bar {
  width: 100% !important;
}

.offcanvas.side_bar .close_button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.offcanvas.side_bar .list-group-item {
  font-size: 20px;
}

.offcanvas.side_bar .list-group.trans {
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 1rem;
}

.body .list-group.trans {
  --bs-list-group-bg: transparent;
  --bs-list-group-border-color: #8f919252
}

.body .list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: var(--bs-list-group-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-list-group-bg);
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: var(--bs-list-group-bg);
  --bs-list-group-active-border-color: var(--bs-list-group-border-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.product_quality_page .product_quality_row img {
  width: 100%;
  height: auto;
  max-width: calc(100% - 60px);
  margin: 0 auto;
  display: block;
}

.product_quality_page .product_quality_row .text-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form_group.form-floating label {
  transition: var(--fr-transition);
}

.form_group.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  opacity: 1;
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  height: auto;
  padding: 0px;
  padding: 0px 0px 0px 10px;
  width: 100%;
  transition: var(--fr-transition);
  transform: scale(0.982) translateY(0.045rem) translateX(0.1rem);
  border-radius: 5px 0 0 0;
}

.form_group.form-floating>.form-control:focus~label,
.form_group.form-floating>.form-control:not(:placeholder-shown)~label,
.form_group.form-floating>.form-select~label {
  opacity: 1;
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  height: auto;
  padding: 0px 0px 0px 10px;
  width: 100%;
  transform: scale(0.982) translateY(0.045rem) translateX(0.1rem);
  transition: var(--fr-transition);
  border-radius: 5px 0 0 0;
  font-size: 12px;
}

.form_group.form-floating>.form-control:-webkit-autofill~label {
  opacity: 1;
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  height: auto;
  padding: 0px;
  padding: 0px 0px 0px 20px;
  width: 100%;
  transition: var(--fr-transition);
  transform: scale(0.982) translateY(0.045rem) translateX(0.1rem);
  border-radius: 5px 0 0 0;
}

.form_group.form-floating textarea {
  min-height: 150px;
}

.close_modal_icon {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 5px;
}

.animation {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

.animation.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse
}

body .has-animation .opacity-animation {
  opacity: 0;
  visibility: hidden;
}

body .has-animation .translate-zoomout-10 {
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
}

body .has-animation .translate-zoomout-20 {
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  transform: scale(0.2);
}

body .has-animation .translate-zoomout-30 {
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
}

body .has-animation .translate-zoomout-40 {
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  transform: scale(0.4);
}

body .has-animation .translate-zoomout-50 {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}

body .has-animation .translate-left-10 {
  -webkit-transform: translateX(-10%);
  -ms-transform: translateX(-10%);
  transform: translateX(-10%);
}

body .has-animation .translate-left-20 {
  -webkit-transform: translateX(-20%);
  -ms-transform: translateX(-20%);
  transform: translateX(-20%);
}

body .has-animation .translate-left-30 {
  -webkit-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  transform: translateX(-30%);
}

body .has-animation .translate-left-40 {
  -webkit-transform: translateX(-40%);
  -ms-transform: translateX(-40%);
  transform: translateX(-40%);
}

body .has-animation .translate-left-50 {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

body .has-animation .translate-left-75 {
  -webkit-transform: translateX(-75%);
  -ms-transform: translateX(-75%);
  transform: translateX(-75%);
}

body .has-animation .translate-left-100 {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

body .has-animation .translate-right-10 {
  -webkit-transform: translateX(10%);
  -ms-transform: translateX(10%);
  transform: translateX(10%);
}

body .has-animation .translate-right-20 {
  -webkit-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
}

body .has-animation .translate-right-30 {
  -webkit-transform: translateX(30%);
  -ms-transform: translateX(30%);
  transform: translateX(30%);
}

body .has-animation .translate-right-40 {
  -webkit-transform: translateX(40%);
  -ms-transform: translateX(40%);
  transform: translateX(40%);
}

body .has-animation .translate-right-50 {
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

body .has-animation .translate-right-75 {
  -webkit-transform: translateX(75%);
  -ms-transform: translateX(75%);
  transform: translateX(75%);
}

body .has-animation .translate-right-100 {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

body .has-animation .translate-top-10 {
  -webkit-transform: translateY(-10%);
  -ms-transform: translateY(-10%);
  transform: translateY(-10%);
}

body .has-animation .translate-top-20 {
  -webkit-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
}

body .has-animation .translate-top-30 {
  -webkit-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
}

body .has-animation .translate-top-40 {
  -webkit-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
}

body .has-animation .translate-top-50 {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

body .has-animation .translate-top-75 {
  -webkit-transform: translateY(-75%);
  -ms-transform: translateY(-75%);
  transform: translateY(-75%);
}

body .has-animation .translate-top-100 {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

body .has-animation .translate-bottom-10 {
  -webkit-transform: translateY(10%);
  -ms-transform: translateY(10%);
  transform: translateY(10%);
}

body .has-animation .translate-bottom-20 {
  -webkit-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
}

body .has-animation .translate-bottom-30 {
  -webkit-transform: translateY(30%);
  -ms-transform: translateY(30%);
  transform: translateY(30%);
}

body .has-animation .translate-bottom-40 {
  -webkit-transform: translateY(40%);
  -ms-transform: translateY(40%);
  transform: translateY(40%);
}

body .has-animation .translate-bottom-50 {
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

body .has-animation .translate-bottom-75 {
  -webkit-transform: translateY(75%);
  -ms-transform: translateY(75%);
  transform: translateY(75%);
}

body .has-animation .translate-bottom-100 {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

body .has-animation .translate-bottom-left-10 {
  -webkit-transform: translateY(10%) translateX(-10%);
  -ms-transform: translateY(10%) translateX(-10%);
  transform: translateY(10%) translateX(-10%);
}

body .has-animation .translate-bottom-left-20 {
  -webkit-transform: translateY(20%) translateX(-20%);
  -ms-transform: translateY(20%) translateX(-20%);
  transform: translateY(20%) translateX(-20%);
}

body .has-animation .translate-bottom-left-30 {
  -webkit-transform: translateY(30%) translateX(-30%);
  -ms-transform: translateY(30%) translateX(-30%);
  transform: translateY(30%) translateX(-30%);
}

body .has-animation .translate-bottom-left-40 {
  -webkit-transform: translateY(40%) translateX(-40%);
  -ms-transform: translateY(40%) translateX(-40%);
  transform: translateY(40%) translateX(-40%);
}

body .has-animation .translate-bottom-left-50 {
  -webkit-transform: translateY(50%) translateX(-50%);
  -ms-transform: translateY(50%) translateX(-50%);
  transform: translateY(50%) translateX(-50%);
}

body .has-animation .translate-bottom-left-75 {
  -webkit-transform: translateY(75%) translateX(-75%);
  -ms-transform: translateY(75%) translateX(-75%);
  transform: translateY(75%) translateX(-75%);
}

body .has-animation .translate-bottom-left-100 {
  -webkit-transform: translateY(100%) translateX(-100%);
  -ms-transform: translateY(100%) translateX(-100%);
  transform: translateY(100%) translateX(-100%);
}

body .has-animation .translate-top-left-10 {
  -webkit-transform: translateY(-10%) translateX(-10%);
  -ms-transform: translateY(-10%) translateX(-10%);
  transform: translateY(-10%) translateX(-10%);
}

body .has-animation .translate-top-left-20 {
  -webkit-transform: translateY(-20%) translateX(-20%);
  -ms-transform: translateY(-20%) translateX(-20%);
  transform: translateY(-20%) translateX(-20%);
}

body .has-animation .translate-top-left-30 {
  -webkit-transform: translateY(-30%) translateX(-30%);
  -ms-transform: translateY(-30%) translateX(-30%);
  transform: translateY(-30%) translateX(-30%);
}

body .has-animation .translate-top-left-40 {
  -webkit-transform: translateY(-40%) translateX(-40%);
  -ms-transform: translateY(-40%) translateX(-40%);
  transform: translateY(-40%) translateX(-40%);
}

body .has-animation .translate-top-left-50 {
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

body .has-animation .translate-top-left-75 {
  -webkit-transform: translateY(-75%) translateX(-75%);
  -ms-transform: translateY(-75%) translateX(-75%);
  transform: translateY(-75%) translateX(-75%);
}

body .has-animation .translate-top-left-100 {
  -webkit-transform: translateY(-100%) translateX(-100%);
  -ms-transform: translateY(-100%) translateX(-100%);
  transform: translateY(-100%) translateX(-100%);
}

body .has-animation .translate-top-right-10 {
  -webkit-transform: translateY(-10%) translateX(10%);
  -ms-transform: translateY(-10%) translateX(10%);
  transform: translateY(-10%) translateX(10%);
}

body .has-animation .translate-top-right-20 {
  -webkit-transform: translateY(-20%) translateX(20%);
  -ms-transform: translateY(-20%) translateX(20%);
  transform: translateY(-20%) translateX(20%);
}

body .has-animation .translate-top-right-30 {
  -webkit-transform: translateY(-30%) translateX(30%);
  -ms-transform: translateY(-30%) translateX(30%);
  transform: translateY(-30%) translateX(30%);
}

body .has-animation .translate-top-right-40 {
  -webkit-transform: translateY(-40%) translateX(40%);
  -ms-transform: translateY(-40%) translateX(40%);
  transform: translateY(-40%) translateX(40%);
}

body .has-animation .translate-top-right-50 {
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}

body .has-animation .translate-top-right-75 {
  -webkit-transform: translateY(-75%) translateX(75%);
  -ms-transform: translateY(-75%) translateX(75%);
  transform: translateY(-75%) translateX(75%);
}

body .has-animation .translate-top-right-100 {
  -webkit-transform: translateY(-100%) translateX(100%);
  -ms-transform: translateY(-100%) translateX(100%);
  transform: translateY(-100%) translateX(100%);
}

body .transition-10,
body .transition-20,
body .transition-30,
body .transition-40,
body .transition-50,
body .transition-100,
body .transition-150,
body .transition-200,
body .transition-250,
body .transition-300,
body .transition-350,
body .transition-400,
body .transition-450,
body .transition-500,
body .transition-550,
body .transition-600,
body .transition-650,
body .transition-700,
body .transition-750,
body .transition-800,
body .transition-850,
body .transition-900,
body .transition-950,
body .transition-1000 {
  transition: all 0.01s ease-out;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

body.loaded .has-animation.active-animation .opacity-animation {
  opacity: 1 !important;
  visibility: visible !important;
}

body.loaded .has-animation.active-animation .translate-zoomout-10,
body.loaded .has-animation.active-animation .translate-zoomout-20,
body.loaded .has-animation.active-animation .translate-zoomout-30,
body.loaded .has-animation.active-animation .translate-zoomout-40,
body.loaded .has-animation.active-animation .translate-zoomout-50 {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

body.loaded .has-animation.active-animation .translate-left-10,
body.loaded .has-animation.active-animation .translate-left-20,
body.loaded .has-animation.active-animation .translate-left-30,
body.loaded .has-animation.active-animation .translate-left-40,
body.loaded .has-animation.active-animation .translate-left-50,
body.loaded .has-animation.active-animation .translate-left-75,
body.loaded .has-animation.active-animation .translate-left-100,
body.loaded .has-animation.active-animation .translate-right-10,
body.loaded .has-animation.active-animation .translate-right-20,
body.loaded .has-animation.active-animation .translate-right-30,
body.loaded .has-animation.active-animation .translate-right-40,
body.loaded .has-animation.active-animation .translate-right-50,
body.loaded .has-animation.active-animation .translate-right-75,
body.loaded .has-animation.active-animation .translate-right-100,
body.loaded .has-animation.active-animation .translate-top-10,
body.loaded .has-animation.active-animation .translate-top-20,
body.loaded .has-animation.active-animation .translate-top-30,
body.loaded .has-animation.active-animation .translate-top-40,
body.loaded .has-animation.active-animation .translate-top-50,
body.loaded .has-animation.active-animation .translate-top-75,
body.loaded .has-animation.active-animation .translate-top-100,
body.loaded .has-animation.active-animation .translate-bottom-10,
body.loaded .has-animation.active-animation .translate-bottom-20,
body.loaded .has-animation.active-animation .translate-bottom-30,
body.loaded .has-animation.active-animation .translate-bottom-40,
body.loaded .has-animation.active-animation .translate-bottom-50,
body.loaded .has-animation.active-animation .translate-bottom-75,
body.loaded .has-animation.active-animation .translate-bottom-100,
body.loaded .has-animation.active-animation .translate-top-left-10,
body.loaded .has-animation.active-animation .translate-top-left-20,
body.loaded .has-animation.active-animation .translate-top-left-30,
body.loaded .has-animation.active-animation .translate-top-left-40,
body.loaded .has-animation.active-animation .translate-top-left-50,
body.loaded .has-animation.active-animation .translate-top-left-75,
body.loaded .has-animation.active-animation .translate-top-left-100,
body.loaded .has-animation.active-animation .translate-top-right-10,
body.loaded .has-animation.active-animation .translate-top-right-20,
body.loaded .has-animation.active-animation .translate-top-right-30,
body.loaded .has-animation.active-animation .translate-top-right-40,
body.loaded .has-animation.active-animation .translate-top-right-50,
body.loaded .has-animation.active-animation .translate-top-right-75,
body.loaded .has-animation.active-animation .translate-top-right-100,
body.loaded .has-animation.active-animation .translate-bottom-left-10,
body.loaded .has-animation.active-animation .translate-bottom-left-20,
body.loaded .has-animation.active-animation .translate-bottom-left-30,
body.loaded .has-animation.active-animation .translate-bottom-left-40,
body.loaded .has-animation.active-animation .translate-bottom-left-50,
body.loaded .has-animation.active-animation .translate-bottom-left-75,
body.loaded .has-animation.active-animation .translate-bottom-left-100 {
  -webkit-transform: translateY(0) translateX(0);
  -ms-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

body.loaded .transition-10 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.1s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.1s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.1s, opacity 0.3s;
}

body.loaded .transition-20 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.2s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.2s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.2s, opacity 0.3s;
}

body.loaded .transition-30 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.3s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.3s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.3s, opacity 0.3s;
}

body.loaded .transition-40 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.4s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.4s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.4s, opacity 0.3s;
}

body.loaded .transition-50 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
}

body.loaded .transition-100 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 1s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 1s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 1s, opacity 0.3s;
}

body.loaded .transition-150 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 1.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 1.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 1.5s, opacity 0.3s;
}

body.loaded .transition-200 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 2s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 2s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 2s, opacity 0.3s;
}

body.loaded .transition-250 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 2.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 2.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 2.5s, opacity 0.3s;
}

body.loaded .transition-300 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 3s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 3s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 3s, opacity 0.3s;
}

body.loaded .transition-350 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 3.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 3.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 3.5s, opacity 0.3s;
}

body.loaded .transition-400 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 4s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 4s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 4s, opacity 0.3s;
}

body.loaded .transition-450 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 4.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 4.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 4.5s, opacity 0.3s;
}

body.loaded .transition-500 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 5s, opacity 0.3s;
}

body.loaded .transition-550 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 5.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 5.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 5.5s, opacity 0.3s;
}

body.loaded .transition-600 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 6s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 6s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 6s, opacity 0.3s;
}

body.loaded .transition-650 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 6.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 6.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 6.5s, opacity 0.3s;
}

body.loaded .transition-700 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 7s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 7s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 7s, opacity 0.3s;
}

body.loaded .transition-750 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 7.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 7.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 7.5s, opacity 0.3s;
}

body.loaded .transition-800 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 8s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 8s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 8s, opacity 0.3s;
}

body.loaded .transition-850 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 8.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 8.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 8.5s, opacity 0.3s;
}

body.loaded .transition-900 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 9s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 9s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 9s, opacity 0.3s;
}

body.loaded .transition-950 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 9.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 9.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 9.5s, opacity 0.3s;
}

body.loaded .transition-1000 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 10s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 10s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 10s, opacity 0.3s;
}

body.loaded .transition-delay-0 {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

body.loaded .transition-delay-10 {
  -webkit-transition-delay: 0.01s;
  -o-transition-delay: 0.01s;
  transition-delay: 0.01s;
}

body.loaded .transition-delay-20 {
  -webkit-transition-delay: 0.02s;
  -o-transition-delay: 0.02s;
  transition-delay: 0.02s;
}

body.loaded .transition-delay-30 {
  -webkit-transition-delay: 0.03s;
  -o-transition-delay: 0.03s;
  transition-delay: 0.03s;
}

body.loaded .transition-delay-40 {
  -webkit-transition-delay: 0.04s;
  -o-transition-delay: 0.04s;
  transition-delay: 0.04s;
}

body.loaded .transition-delay-50 {
  -webkit-transition-delay: 0.05s;
  -o-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

body.loaded .transition-delay-100 {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

body.loaded .transition-delay-200 {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

body.loaded .transition-delay-300 {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

body.loaded .transition-delay-400 {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

body.loaded .transition-delay-500 {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

body.loaded .transition-delay-600 {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

body.loaded .transition-delay-700 {
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

body.loaded .transition-delay-800 {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

body.loaded .transition-delay-900 {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

body.loaded .transition-delay-1000 {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

body.loaded .transition-delay-1100 {
  -webkit-transition-delay: 1.1s;
  -o-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

body.loaded .transition-delay-1200 {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

body.loaded .transition-delay-1300 {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

body.loaded .transition-delay-1400 {
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

body.loaded .transition-delay-1500 {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

body.loaded .transition-delay-1600 {
  -webkit-transition-delay: 1.6s;
  -o-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

body.loaded .transition-delay-1700 {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

body.loaded .transition-delay-1800 {
  -webkit-transition-delay: 1.8s;
  -o-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

body.loaded .transition-delay-1900 {
  -webkit-transition-delay: 1.9s;
  -o-transition-delay: 1.9s;
  transition-delay: 1.9s;
}

body.loaded .transition-delay-2000 {
  -webkit-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}

body.loaded .transition-delay-2100 {
  -webkit-transition-delay: 2.1s;
  -o-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

body.loaded .transition-delay-2200 {
  -webkit-transition-delay: 2.2s;
  -o-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

body.loaded .transition-delay-2300 {
  -webkit-transition-delay: 2.3s;
  -o-transition-delay: 2.3s;
  transition-delay: 2.3s;
}

body.loaded .transition-delay-2400 {
  -webkit-transition-delay: 2.4s;
  -o-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

body.loaded .transition-delay-2500 {
  -webkit-transition-delay: 2.5s;
  -o-transition-delay: 2.5s;
  transition-delay: 2.5s;
}

body.loaded .transition-delay-2600 {
  -webkit-transition-delay: 2.6s;
  -o-transition-delay: 2.6s;
  transition-delay: 2.6s;
}

body.loaded .transition-delay-2700 {
  -webkit-transition-delay: 2.7s;
  -o-transition-delay: 2.7s;
  transition-delay: 2.7s;
}

body.loaded .transition-delay-2800 {
  -webkit-transition-delay: 2.8s;
  -o-transition-delay: 2.8s;
  transition-delay: 2.8s;
}

body.loaded .transition-delay-2900 {
  -webkit-transition-delay: 2.9s;
  -o-transition-delay: 2.9s;
  transition-delay: 2.9s;
}

body.loaded .transition-delay-3000 {
  -webkit-transition-delay: 3s;
  -o-transition-delay: 3s;
  transition-delay: 3s;
}

body.loaded .transition-delay-3100 {
  -webkit-transition-delay: 3.1s;
  -o-transition-delay: 3.1s;
  transition-delay: 3.1s;
}

body.loaded .transition-delay-3200 {
  -webkit-transition-delay: 3.2s;
  -o-transition-delay: 3.2s;
  transition-delay: 3.2s;
}

body.loaded .transition-delay-3300 {
  -webkit-transition-delay: 3.3s;
  -o-transition-delay: 3.3s;
  transition-delay: 3.3s;
}

body.loaded .transition-delay-3400 {
  -webkit-transition-delay: 3.4s;
  -o-transition-delay: 3.4s;
  transition-delay: 3.4s;
}

body.loaded .transition-delay-3500 {
  -webkit-transition-delay: 3.5s;
  -o-transition-delay: 3.5s;
  transition-delay: 3.5s;
}

body.loaded .transition-delay-3600 {
  -webkit-transition-delay: 3.6s;
  -o-transition-delay: 3.6s;
  transition-delay: 3.6s;
}

body.loaded .transition-delay-3700 {
  -webkit-transition-delay: 3.7s;
  -o-transition-delay: 3.7s;
  transition-delay: 3.7s;
}

body.loaded .transition-delay-3800 {
  -webkit-transition-delay: 3.8s;
  -o-transition-delay: 3.8s;
  transition-delay: 3.8s;
}

body.loaded .transition-delay-3900 {
  -webkit-transition-delay: 3.9s;
  -o-transition-delay: 3.9s;
  transition-delay: 3.9s;
}

body.loaded .transition-delay-4000 {
  -webkit-transition-delay: 4s;
  -o-transition-delay: 4s;
  transition-delay: 4s;
}

body.loaded .transition-delay-4100 {
  -webkit-transition-delay: 4.1s;
  -o-transition-delay: 4.1s;
  transition-delay: 4.1s;
}

body.loaded .transition-delay-4200 {
  -webkit-transition-delay: 4.2s;
  -o-transition-delay: 4.2s;
  transition-delay: 4.2s;
}

body.loaded .transition-delay-4300 {
  -webkit-transition-delay: 4.3s;
  -o-transition-delay: 4.3s;
  transition-delay: 4.3s;
}

body.loaded .transition-delay-4400 {
  -webkit-transition-delay: 4.4s;
  -o-transition-delay: 4.4s;
  transition-delay: 4.4s;
}

body.loaded .transition-delay-4500 {
  -webkit-transition-delay: 4.5s;
  -o-transition-delay: 4.5s;
  transition-delay: 4.5s;
}

body.loaded .transition-delay-4600 {
  -webkit-transition-delay: 4.6s;
  -o-transition-delay: 4.6s;
  transition-delay: 4.6s;
}

body.loaded .transition-delay-4700 {
  -webkit-transition-delay: 4.7s;
  -o-transition-delay: 4.7s;
  transition-delay: 4.7s;
}

body.loaded .transition-delay-4800 {
  -webkit-transition-delay: 4.8s;
  -o-transition-delay: 4.8s;
  transition-delay: 4.8s;
}

body.loaded .transition-delay-4900 {
  -webkit-transition-delay: 4.9s;
  -o-transition-delay: 4.9s;
  transition-delay: 4.9s;
}

body.loaded .transition-delay-5000 {
  -webkit-transition-delay: 5s;
  -o-transition-delay: 5s;
  transition-delay: 5s;
}

body.loaded .transition-delay-5100 {
  -webkit-transition-delay: 5.1s;
  -o-transition-delay: 5.1s;
  transition-delay: 5.1s;
}

body.loaded .transition-delay-5200 {
  -webkit-transition-delay: 5.2s;
  -o-transition-delay: 5.2s;
  transition-delay: 5.2s;
}

body.loaded .transition-delay-5300 {
  -webkit-transition-delay: 5.3s;
  -o-transition-delay: 5.3s;
  transition-delay: 5.3s;
}

body.loaded .transition-delay-5400 {
  -webkit-transition-delay: 5.4s;
  -o-transition-delay: 5.4s;
  transition-delay: 5.4s;
}

body.loaded .transition-delay-5500 {
  -webkit-transition-delay: 5.5s;
  -o-transition-delay: 5.5s;
  transition-delay: 5.5s;
}

body.loaded .transition-delay-5600 {
  -webkit-transition-delay: 5.6s;
  -o-transition-delay: 5.6s;
  transition-delay: 5.6s;
}

body.loaded .transition-delay-5700 {
  -webkit-transition-delay: 5.7s;
  -o-transition-delay: 5.7s;
  transition-delay: 5.7s;
}

body.loaded .transition-delay-5800 {
  -webkit-transition-delay: 5.8s;
  -o-transition-delay: 5.8s;
  transition-delay: 5.8s;
}

body.loaded .transition-delay-5900 {
  -webkit-transition-delay: 5.9s;
  -o-transition-delay: 5.9s;
  transition-delay: 5.9s;
}

body.loaded .transition-delay-6000 {
  -webkit-transition-delay: 6s;
  -o-transition-delay: 6s;
  transition-delay: 6s;
}

.product_palmur_richson {
  font-family: "Orbitron";
  font-size: 33px;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
}

.header_text {
  font-family: "Manrope";
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  color: #FFFFFF;
}

.header_btn {
  font-family: "Orbitron";
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  color: "#FFFFFF"
}

.breadcrum_header {
  font-family: "Orbitron";
  font-size: 15px;
  font-weight: 500;
  line-height: 18.81px;
}

.product_expirence {
  font-family: "Orbitron";
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  text-align: center;
  color: #1A1D1F;
  ;
}

.product_button_buy {
  font-family: "Orbitron" !important;
  width: 133px;
  height: 41px;
  /* top: 2146px; */
  left: 70px;
  gap: 0px;
  /* opacity: 0px; */
  border: 1px solid #D18B22 !important;
  color: #1A1D1F !important;
  padding: 0 !important;
}

.header_text {
  font-family: "Orbitron";
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;

}

.our_product_courosol {
  background-color: #1A1D1F;
}

.our_product_text {
  font-family: "Orbitron";
  font-size: 35px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
}

.text_align {
  text-align: center;
  color: #F9F9F9;
}

.image-container {
  max-width: 100%;
  overflow-x: auto;
}

.overflow-x-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

.tab {
  font-family: Orbitron;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.bg_color_tab {
  background-color: #B47A23 !important
}

.img {
  width: 315px;
  height: 395.06px;
  top: 982px;
  left: 179px;
  gap: 0px;
  /* opacity: 0px; */
}

.scroll_img {
  width: 100%;
  height: 545px;
  /* top: 2491px; */
  gap: 0px;
  /* padding: 0px 65px 0px !important; */
  /* opacity: 0px; */

}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Adjust this property as needed */
}

.header_brand_status {
  font-family: "Orbitron";
  font-size: 30px;
  font-weight: 600;
  /* line-height: 64px; */
  /* text-align: left; */
  color: #171A1F;
}

.brand_header {
  font-family: "Orbitron";
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
}

.brand_status_sub_img {
  width: 64px;
  height: 64px;
  top: 1951px;
  left: 635px;
  gap: 0px;
  /* opacity: 0px; */
  background: #D18B22;
  -webkit-border-radius: 50%;
}

.percentage {
  float: right;
  margin-right: 0;
  /* Adjust this value to control the space between the text and the image */
  margin-left: 10px;
  /* Adjust this value to control the space between the percentage and the text */
}

.order_btn {
  font-family: "Manrope";
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: left !important;
  color: #FFFFFF !important;
  background-color: #060605;

  width: 140px;
  height: 48px !important;
  top: 2350px !important;
  left: 636px !important;
  padding: 12px 31px 12px 31px !important;
  gap: 0px;
  /* opacity: 0px; */
  Rotation: -0 deg;

}

.color_progressbar {
  background: linear-gradient(90deg, #FFE8C6 0%, #D18B22 100%);
}

.custom-progress-bar .progress-bar {
  /* background-color: orange !important;
  border-color: orange !important; */
  background: linear-gradient(90deg, #FFE8C6 0%, #D18B22 100%);
}

.background_image_banner {
  background-image: url(http://localhost:3000/static/media/bg.b1e48e9be4d464d78749.webp);
  height: 100%;
  min-height: 100vh;
  position: relative;
  color: var(--color-white);
  background-attachment: fixed;
}

.header_leftside_text {
  font-family: Orbitron;
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  text-align: left;
  color: #FFFFFF;
}

.color_header_leftside_text {
  font-family: Orbitron;
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  color: #F4A25C;
}

.divider {
  border: 1px solid #323638 !important;
}

.typography_text {
  font-family: "Manrope", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  /* text-align: left; */
  color: #FFFFFF;
}

.btn_color_text {
  border: 1px solid #D18B22 !important;
  color: #FFFFFF !important;
  font-family: "Orbitron" !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  /* line-height: 50px !important; */
  text-align: left !important;
  /* width: 177px !important; */
  height: 49px !important;
}

.boxCard_bg {
  background-color: #232628 !important;
}

.card_height_width {
  width: 400px;
  height: 459px;
  top: 885px;
  left: 90px;
  gap: 0px;
  border-radius: 37px 0px 0px 0px;
  /* opacity: 0px; */
}

.header_text_size {
  color: rgb(255, 255, 255);
  font-family: Orbitron;
  font-size: 18px !important;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.header_number {
  color: #FFFFFF;
  font-family: 'Manrope';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;

}

.header_address {
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  color: rgb(241 193 105) !important;
}

.header_side_main_content {
  font-family: "Orbitron" !important;
  font-size: 35px !important;
  font-weight: 600 !important;
  line-height: 50px;
  text-align: left !important;
  color: #D18B22 !important;
}

.header_side_content {
  font-family: "Orbitron" !important;
  font-size: 35px !important;
  font-weight: 600 !important;
  line-height: 50px;
  text-align: left !important;
  color: #FFFFFF !important;
}

.btn_header {
  width: 141px !important;
  height: 49px !important;
  top: 435px !important;
  left: 94px !important;
  gap: 0px !important;
  /* opacity: 0px !important; */
  /* background: #D18B22 !important; */
}

.btn_header_text {
  font-family: "Orbitron" !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 50px !important;
  text-align: left !important;
  color: #FFFFFF !important;
}

.carousel_img_bg {
  background-image: url('./../img/new_product_img/background.webp');
  background-repeat: no-repeat;
  height: 490px;
  width: 320px !important;
  position: fixed;
}

.footer_link {
  font-family: "Orbitron";
  font-size: 16px;
  font-weight: 500;
  line-height: 20.06px;
}

.product_text {
  font-family: "Orbitron";
  font-size: 60px !important;
  font-weight: 900;
  line-height: 64px;
  text-align: left;
  color: #363A3D;

}

.our_product_text_for_details {
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
}

.sub_product_text {
  font-family: "Manrope", sans-serif !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  /* text-align: left; */
}

.product_palmur_richson_subtext {
  font-family: Orbitron;
  font-size: 45px;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
}

.our_product_buy_text {
  font-family: Orbitron;
  font-size: 13px;
  font-weight: 600;
  /* line-height: 50px; */
  text-align: center;
  color: #1A1D1F;
  padding-left: 4px;
}

.home_page_carousel {
  /* background-image: url("./../img/new_product_img/header_scrolling1.webp"); */
  width: 100%;
  height: 655px;
  gap: 0px;
  /* opacity: 0px; */
  background-size: cover;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #B47A23 !important;
}

.scrolling_btn_more_details {
  width: 104px;
  height: 34px;
  top: 1503px;
  left: 661px;
  gap: 0px;
  /* opacity: 0px; */
}

.scrolling_color_decription {
  font-family: Orbitron;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}

.disable-hover:hover {
  pointer-events: none;
}

.carousel-control-next-icon {
  margin-right: -10px;
}

.carousel-control-prev-icon {
  margin-left: -110px;
}

.carousel-control-prev {
  left: 49px !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

.nav {
  --bs-nav-link-color: black !important;
}

.brandStatus>a:nth-child(4)>span {
  background-color: rgb(180 176 176) !important;
}

.brandStatus>a:nth-child(3)>span {
  background-color: rgb(180 176 176) !important;
}

.section_product>div>a:nth-child(4)>span {
  background-color: rgb(180 176 176) !important;
}

.section_product>div>a:nth-child(3)>span {
  background-color: rgb(180 176 176) !important;
}

.pal_rich_header {
  width: 115% !important;
  height: 500px !important
}

.our_product_img {
  height: 450px;
  margin: 10px 0 0 0
}

.home_carousel>div>a:nth-child(3) {
  display: none;
}

.home_carousel>div>a:nth-child(4) {
  display: none;
}

.brand_img {
  width: auto;
  height: 480px !important
}

.product_quality {
  color: rgb(30 26 22);
  font-family: Orbitron !important;
  font-size: 1.700rem !important
}


@media (max-width: 768px) {
  .home_page_carousel {
    width: 100%;
    height: 345px;
    /* height: auto; */
  }

  .header_side_content {
    /* font-size: 15px !important; */
    line-height: 30px;
  }

  .home .home_wrapper .home_carousel .home_content {
    padding: 0rem;
  }

  header>nav>div>div>div>div:nth-child(3)>button:nth-child(1) {
    background-color: #454545 !important;
  }

  .our_product_img {
    height: auto !important;
    margin: 0 !important
  }

  .our_product_text {
    font-size: 20px !important;
  }


  .scrolling_color_decription {
    font-size: 15px !important;
  }

  .brand_header {
    /* font-size: 25px !important; */
  }

  .header_side_main_content {
    /* font-size: 20px !important;  */
  }


  .scroll_img {
    height: auto;
    padding: 0;
  }

  .pal_rich_header {
    width: 100%;
    height: auto;
  }

  .product_text {
    font-size: 45px !important;
  }

  .brand_header {
    font-size: 25px !important;
  }

  .brand_img {
    height: 400px;
    padding-left: 20px;
  }

  
.product_quality {
  font-size: 1rem;
}
}


/* Responsive Media Queries */
@media (min-width: 600px) {
  .our_product_text {
    font-size: 2rem;
  }

  .scrolling_color_decription {
    font-size: 1.125rem;
  }

  .btn_header_text {
    font-size: 1.125rem;
  }

  .img {
    height: 450px;
    margin: 10px 0 0 0;
  }

  .scroll_img {
    height: auto;
    padding: 0;
  }

  .pal_rich_header {
    width: 100%;
    height: auto;
  }

  .product_text {
    font-size: 45px !important;
  }

  .brand_header {
    font-size: 25px !important;
  }

  .tab_flex_row {
    justify-content: center;
  }

  .brand_img {
    height: 400px;
    padding-left: 20px;
  }

  .product_quality {
    font-size: 1rem;
  }
}

@media (max-width: 599px) {
  .our_product_text {
    font-size: 1.25rem;
  }

  .scrolling_color_decription {
    font-size: 0.875rem;
  }

  .btn_header_text {
    font-size: 0.875rem;
  }

  .img {
    height: 220px;
    margin: 20px 0 0 0;
  }


  .scroll_img {
    height: auto;
    padding: 0;
  }

  .pal_rich_header {
    width: 100%;
    height: auto;
  }

  .product_text {
    font-size: 45px !important;
  }

  .brand_header {
    font-size: 25px !important;
  }

  .tab_flex_row {
    justify-content: center;
  }

  .brand_img {
    height: 400px;
    padding-left: 20px;
  }

  .product_quality {
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .scrolling_color_decription {
    font-size: 1.25rem;
  }

  .btn_header_text {
    font-size: 1.25rem;
  }

  .carousel_img_bg {
    height: 550px;
    width: 370px;
  }

  .img {
    height: 500px;
    margin: 15px 0 0 0;
  }

  .tab_flex_row {
    justify-content: left;
  }
}

@media (min-width: 2000px) {

  .scrolling_color_decription {
    font-size: 1.5rem;
  }

  .btn_header_text {
    font-size: 1.5rem;
  }

  .carousel_img_bg {
    height: 600px;
    /* width: 420px; */
  }

  .img {
    height: 550px;
    margin: 20px 0 0 0;
  }

  .home_page_carousel {
    height: 1000px;
  }

  .tab_flex_row {
    justify-content: left;
  }
}

@media screen and (min-width: 1920px) {
  .home_page_carousel {
    height: 1000px;
  }

  .tab_flex_row {
    justify-content: left;
  }
}